import { MANDATE_GROUP, MANDATE_STATE } from '@margobank/components/domain/mandates';
import { OPERATION_REFUSAL_REASON, OPERATION_TYPE } from '@margobank/components/domain/operations';
import { USER_ACCESS_ROLE } from '@margobank/components/domain/user';
import { Enum } from '@memobank/utils';

export const ACCOUNT_NUMBER_STATUS = Enum('ACTIVE', 'INACTIVE');

export const ACCOUNT_NUMBER_TYPE = Enum('MAIN_IBAN', 'SECONDARY_IBAN');

export const BALANCE_STATE = Enum('NEGATIVE', 'POSITIVE');

export const BALANCE_STATE_DURATION_WARNING_DAYS = 5;

export const DEFAULT_OPERATIONS_PAGE_SIZE = 50;

export const DEFAULT_TRANSFERS_OPERATION_TYPES = [
  OPERATION_TYPE.GROUP_INSTANT_TRANSFER,
  OPERATION_TYPE.GROUP_TRANSFER,
  OPERATION_TYPE.RECURRING_TRANSFER,
  OPERATION_TYPE.SINGLE_INSTANT_TRANSFER,
  OPERATION_TYPE.SINGLE_TRANSFER,
  OPERATION_TYPE.WIRE_TRANSFER,
];

export const GROUP_OPERATION_STATUS = Enum('PROCESSED', 'CANCELLED', 'ERROR');

export const INSTANT_TRANSFER_REFUSAL_REASONS = [
  OPERATION_REFUSAL_REASON.COUNTERPART_BANK_ACCOUNT_CLOSED,
  OPERATION_REFUSAL_REASON.COUNTERPART_BANK_ERROR,
  OPERATION_REFUSAL_REASON.COUNTERPART_BANK_INVALID_BANK_DETAILS,
  OPERATION_REFUSAL_REASON.COUNTERPART_BANK_REFUSAL,
  OPERATION_REFUSAL_REASON.INTERMEDIARY_SYSTEM_ERROR,
  OPERATION_REFUSAL_REASON.MEMO_ERROR,
  OPERATION_REFUSAL_REASON.MEMO_REFUSAL,
] as const;

export const INSUFFICIENT_BALANCE_FOR_FUTURE_OPERATIONS_WARNING_DAYS = 2;

export const MAX_OPERATIONS_PAGE_SIZE = 500;

export const USER_ACCESS_ROLE_INVITABLE = [USER_ACCESS_ROLE.ADMIN];

export const MANDATE_STATE_TO_GROUP = {
  [MANDATE_STATE.ACTIVE]: MANDATE_GROUP.ACTIVE,
  [MANDATE_STATE.PENDING]: MANDATE_GROUP.PENDING,
  [MANDATE_STATE.REFUSED]: MANDATE_GROUP.INACTIVE,
  [MANDATE_STATE.SUSPENDED]: MANDATE_GROUP.INACTIVE,
};

export const MISSING_ATTACHMENTS_OPERATION_TYPE = Enum('CARD', 'TRANSFER');

export const TRANSFER_OPERATION_STATUS = Enum('PENDING', 'SUCCESS', 'FAILURE');
