import type { PaginatedList } from '@margobank/components/common/types';
import type {
  CustomerSubscriptionAdditionalChargesDTO,
  CustomerSubscriptionInformationV2DTO,
  SubscriptionPaymentDTO,
} from '@margobank/components/domain/subscriptions';
import { createFileActionsHook, createQueryHook } from '@margobank/components/query-helpers';

import http, { SUBSCRIPTIONS_SERVICE_PREFIX, V2_ACCEPT_HEADER_VALUE } from 'common/http';

import type { CurrentSubscriptionDTO } from './types';

const LOAD_CURRENT_SUBSCRIPTION = 'LOAD_CURRENT_SUBSCRIPTION';
const LOAD_SUBSCRIPTION_ADDITIONAL_CHARGES = 'LOAD_SUBSCRIPTION_ADDITIONAL_CHARGES';
const LOAD_SUBSCRIPTION_INFORMATION = 'LOAD_SUBSCRIPTION_INFORMATION_V2';
const LOAD_SUBSCRIPTION_PAYMENTS = 'LOAD_SUBSCRIPTION_PAYMENTS';

type UseLoadCurrentSubscription = {
  customerId: string;
};

export const useLoadCurrentSubscription = createQueryHook(
  LOAD_CURRENT_SUBSCRIPTION,
  async ({ customerId }: UseLoadCurrentSubscription) => {
    const { data } = await http.get<CurrentSubscriptionDTO>(
      `${SUBSCRIPTIONS_SERVICE_PREFIX}/customers/${customerId}/current_subscription`,
    );
    return data;
  },
);

type UseLoadSubscriptionAdditionalCharges = {
  customerId: string;
};

export const useLoadSubscriptionAdditionalCharges = createQueryHook(
  LOAD_SUBSCRIPTION_ADDITIONAL_CHARGES,
  async ({ customerId }: UseLoadSubscriptionAdditionalCharges) => {
    const { data } = await http.get<CustomerSubscriptionAdditionalChargesDTO>(
      `${SUBSCRIPTIONS_SERVICE_PREFIX}/customers/${customerId}/additional_charges`,
    );
    return data.additionalCharges;
  },
);

type UseLoadSubscriptionInformation = {
  customerId: string;
};

export const useLoadSubscriptionInformation = createQueryHook(
  LOAD_SUBSCRIPTION_INFORMATION,
  async ({ customerId }: UseLoadSubscriptionInformation) => {
    const { data } = await http.get<CustomerSubscriptionInformationV2DTO>(
      `${SUBSCRIPTIONS_SERVICE_PREFIX}/customers/${customerId}/subscription_information`,
      {
        headers: {
          Accept: V2_ACCEPT_HEADER_VALUE,
          'Content-Type': V2_ACCEPT_HEADER_VALUE,
        },
      },
    );
    return data;
  },
);

type UseLoadSubscriptionPayments = {
  customerId: string;
};

export const useLoadSubscriptionPayments = createQueryHook(
  LOAD_SUBSCRIPTION_PAYMENTS,
  async ({ customerId }: UseLoadSubscriptionPayments) => {
    const { data } = await http.get<PaginatedList<SubscriptionPaymentDTO>>(
      `${SUBSCRIPTIONS_SERVICE_PREFIX}/customers/${customerId}/payments`,
    );
    return data;
  },
);

type UseSubscriptionInvoiceFileActions = {
  customerId: string;
  paymentId: string;
};

export const useSubscriptionInvoiceFileActions = createFileActionsHook(
  async ({ customerId, paymentId }: UseSubscriptionInvoiceFileActions) => {
    const { data } = await http.get<Blob>(
      `${SUBSCRIPTIONS_SERVICE_PREFIX}/customers/${customerId}/payments/${paymentId}/invoice`,
      { responseType: 'blob' },
    );
    return data;
  },
);
