declare global {
  interface Window {
    env: Env;
  }
}

export type Env = {
  AUTOMATION_API_ROOT: string;
  BACKEND_API_ROOT: string;
  ENVIRONMENT: 'development' | 'integration' | 'preproduction' | 'production' | 'sandbox' | 'test';
  FAKE_API_ROOT?: string;
  FAKE_WEBAPP_URL?: string;
  GOOGLE_TAG_MANAGER_ID?: string;
  HELPSCOUT_BEACON_ID: string;
  PRISMIC_API_ENDPOINT: string;
  RELEASE: string;
  SENTRY_DSN?: string;
};

const { env } = window;

export const RELEASE = import.meta.env.VITE_APP_RELEASE || '';

export const {
  AUTOMATION_API_ROOT,
  BACKEND_API_ROOT,
  ENVIRONMENT,
  FAKE_API_ROOT,
  FAKE_WEBAPP_URL,
  GOOGLE_TAG_MANAGER_ID,
  HELPSCOUT_BEACON_ID,
  PRISMIC_API_ENDPOINT,
  SENTRY_DSN,
} = env;
