import { create as createStore } from 'zustand';

import { useFeature } from '@margobank/components/feature-toggles';

import { AVAILABLE_FEATURE } from 'common/constants';

type CustomerStore = {
  isFullSearchDisabled: boolean;
};

const useCustomerStore = createStore<CustomerStore>()(() => ({
  isFullSearchDisabled: false,
}));

export const useIsFullSearchDisabled = () => {
  const isSandboxEnabled = useFeature(AVAILABLE_FEATURE.SANDBOX);

  return useCustomerStore(({ isFullSearchDisabled }) =>
    isSandboxEnabled ? true : isFullSearchDisabled,
  );
};

export default useCustomerStore;
